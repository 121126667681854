<!-- 
    https://w3c.github.io/aria-practices/examples/menu-button/menu-button-links.html 
-->
<template>
	<div
		class="dropdown t-dropdown"
		:class="{ 'dropdown--has-filter': hasFilter, 'text-end': isLeftAlign }"
		tabindex="-1"
		@blur="onBlur()"
		@hidden="highlightedIndex = -1"
	>
		<component
			:is="isContextMenu ? 'div' : 'ElButton'"
			aria-haspopup="true"
			class="h-100 t-dropdown-btn flex-grow-1"
			:class="{
				'flex-grow-1': isContextMenu,
				[buttonVariant]: !isContextMenu,
			}"
			:id="`dropdown-btn-${id}`"
			:aria-expanded="`${isPopperExpanded}`"
			:aria-controls="`dropdown-menu-${id}`"
			@click="onClick($event)"
			@click.right="onClick($event)"
			@keydown.prevent="handleKeyDown"
			ref="referenceElement"
		>
			<slot name="dropdown-trigger"></slot>
		</component>
		<div
			class="dropdown-menu shadow t-dropdown-menu"
			role="menu"
			:aria-labelledby="`dropdown-menu-${id}`"
			:class="{ show }"
			:id="`dropdown-menu-${id}`"
			ref="popperElement"
			:style="{ left: `${menuPosition}` }"
		>
			<!-- :style="{ minWidth: popupWidth }" -->
			<div class="dropdown-filter" v-if="hasFilter">
				<ElInput
					class="mb-2"
					v-model="value"
					:placeholder="filterPlaceholder"
					@keydown="handleKeyDown"
					ref="dropdownFilter"
				/>
			</div>
			<div
				:class="[
					'dropdown-content custom-scrollbar',
					dropdownContentClasses,
				]"
				:style="{ maxHeight: contentMaxHeight }"
			>
				<!-- :class="{ 'overflow-scroll-y': popupMaxHeight }" -->
				<!-- :style="{ maxHeight: popupMaxHeight }" -->
				<slot
					name="dropdown-content"
					:hide-dropdown="hidePopper"
				></slot>
			</div>
		</div>
	</div>
</template>

<script>
	import { popper } from '@/functions/mixins/ui/popper';
	import { select } from '@/functions/mixins/ui/select';

	export default {
		mixins: [popper, select],

		props: {
			modelValue: {
				type: [String, Object, Array],
				default() {
					return '';
				},
			},
			buttonVariant: {
				type: String,
				default: '',
			},
			dropdownContentClasses: {
				type: String,
				default: '',
			},
			filterPlaceholder: {
				type: String,
				default: '',
			},
			hasFilter: {
				type: Boolean,
				default: false,
			},
			triggerType: {
				type: String,
				default: 'button',
			},
			isLeftAlign: {
				type: Boolean,
				default: false,
			},
			menuPosition: {
				type: String,
				default: '0%',
			},
			// popupWidth: {
			// 	type: String,
			// 	default: null,
			// },
			// popupMaxHeight: {
			// 	type: String,
			// 	default: null,
			// },
			contentMaxHeight: {
				type: String,
				default: null,
			},
		},

		emits: ['update:modelValue'],

		data() {
			return {
				value: '',
			};
		},

		watch: {
			value(newVal) {
				this.$emit('update:modelValue', newVal);
				this.resetHighlightedIndex();
				this.$nextTick(() => {
					this.setSelectableElements();
					this.resetPopperScrollPosition();
				});
			},

			isPopperExpanded(newVal) {
				if (newVal === false) {
					this.resetHighlightedIndex();
					this.value = '';
				}
			},
		},

		computed: {
			show() {
				let show = null;

				if (this.isPopperExpanded) show = 'show';

				return show;
			},
			isContextMenu() {
				return this.triggerType === 'context-menu';
			},
		},

		methods: {
			handlePopper() {
				// Prevent more than one popup from being open at once
				const openPopup = document.querySelector('.dropdown-menu.show');
				if (openPopup) openPopup.classList.remove('show');

				this.showPopper();

				if (!this.hasFilter) return;
				this.$nextTick(() => this.$refs.dropdownFilter.$el.focus());
			},

			onKeyEnter() {
				if (!this.isPopperExpanded) this.handlePopper();

				if (this.highlightedIndex !== -1) {
					this.options[this.highlightedIndex].click();
					this.hidePopper();
				}
			},

			triggerPopper() {
				this.isPopperExpanded ? this.hidePopper() : this.handlePopper();
			},

			// ---
			// triggers
			onClick(e) {
				if (e.button === 0 && !this.isContextMenu) {
					// 0 = mouse left button
					e.stopPropagation();
					this.triggerPopper();
				} else if (e.button === 2 && this.isContextMenu) {
					// 2 = mouse right button
					e.preventDefault();
					this.triggerPopper();
				}
			},
		},

		mounted() {
			this.setSelectableElements();
		},
	};
</script>
