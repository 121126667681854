/**
 * ATTENTION
 *
 * Please be careful editing this file as it affect dates and time platform wise
 * Dates are always sent to API in unix format (utc timestamp) and should always
 * be displayed on the store configuration timezone (getStoreTimezone())
 *
 * Be aware of the following:
 * - If you need a dayjs object with current time on the store timezone, call $date().toDate()
 * - If you need to convert a timestamp from the API to a dayjs object, call $date().toDate(<timestamp>)
 * - format() will always take a unix value (timestamp utc) and parse to the desired format using the store timezone config
 *
 */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { getStoreTimezone } from '@/functions/common';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

const thresholds = [
	{ l: 's', r: 1, d: 'second' },
	{ l: 'm', r: 1 },
	{ l: 'mm', r: 59, d: 'minute' },
	{ l: 'h', r: 1 },
	{ l: 'hh', r: 23, d: 'hour' },
	{ l: 'd', r: 1 },
	{ l: 'dd', r: 29, d: 'day' },
	{ l: 'M', r: 1 },
	{ l: 'MM', r: 11, d: 'month' },
	{ l: 'y' },
	{ l: 'yy', d: 'year' },
];

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime, { thresholds });
dayjs.extend(weekday);
dayjs.extend(dayOfYear);

export default {
	install: (app) => {
		app.config.globalProperties.$date = function () {
			/**
			 * Validate timstamp to always return 10 digits
			 *
			 * @param {int} timestamp
			 * @returns {int}
			 */

			const checkTimestamp = (timestamp) => {
				if (timestamp == null) {
					return timestamp;
				}

				if ('' + timestamp.toString().length > 10) {
					const timeLength = timestamp.toString().length - 10;
					let divide = 10;
					const tHp = '0';

					if (timeLength > 1) {
						divide = parseInt('1' + tHp.repeat(timeLength));
					}

					timestamp = Math.floor(timestamp / divide);
				}

				return timestamp;
			};

			/**
			 * Formats unix timestamp following passed format
			 *
			 * @param {int} timestamp
			 * @param {string} as
			 * @returns {string}
			 */
			const format = (timestamp, as) => {
				if (isUndefined(timestamp) || isNull(timestamp)) return '';

				if (typeof timestamp === 'object') {
					timestamp = timestamp.value;
				}

				if (timestamp === '' || timestamp === '0') return '';

				timestamp = checkTimestamp(timestamp);

				return toDate(timestamp).format(as);
			};

			/**
			 * https://day.js.org/docs/en/display/difference
			 *
			 * @param {int} timestamp
			 * @param {string} unit
			 * @param {string} as
			 * @returns {string}
			 */
			const diff = (timestamp, unit = 'day') => {
				timestamp = checkTimestamp(timestamp);

				let now = toDate();
				let saved = toDate(timestamp);

				return now.diff(saved, unit);
			};

			/**
			 * https://day.js.org/docs/en/display/from
			 *
			 * @param {int} timestamp
			 * @returns {string}
			 */
			const from = (timestamp) => {
				timestamp = checkTimestamp(timestamp);

				const now = toDate();
				const saved = toDate(timestamp);

				const hours = now.diff(saved, 'hour');
				const minutes = now
					.subtract(hours, 'hour')
					.diff(saved, 'minutes');
				if (hours <= 0 || minutes == 0) return saved.fromNow();

				const hourText = hours < 2 ? 'hour' : 'hours';
				const minText = minutes < 2 ? 'minute' : 'minutes';

				return `${hours} ${hourText} and ${minutes} ${minText} ago`;
			};

			/**
			 *
			 * @param {int} timestamp
			 * @returns {object} dayjs object
			 */
			const toDate = (timestamp) => {
				if (
					isUndefined(timestamp) ||
					isNull(timestamp) ||
					timestamp <= 0
				)
					timestamp = currentUtcUnix();

				timestamp = checkTimestamp(timestamp);

				if (getStoreTimezone())
					return dayjs.tz(dayjs.unix(timestamp), getStoreTimezone());

				return dayjs.unix(timestamp);
			};

			/**
			 *
			 * @param {object, string} date
			 * @returns {number} timestamp
			 */
			const toUnix = (date) => {
				// dates always need hour and minutes
				if (date.length <= 10) {
					// if doesn't have hour and minute, add current
					let hour = toDate().hour();
					let minute = toDate().minute();
					date = `${date} ${hour}:${minute}`;
				}

				let _this = dayjs(date);
				if (getStoreTimezone())
					_this = dayjs(date).tz(getStoreTimezone(), true);

				return _this.unix();
			};

			/**
			 * Returns an UTC dayjs object
			 * @returns {object} utc dayjs object
			 */
			const currentUtc = () => {
				return dayjs.utc();
			};

			/**
			 * Returns current unix UTC timestamp
			 * @returns {number} utc unix timestamp
			 */
			const currentUtcUnix = () => {
				return currentUtc().unix();
			};

			/**
			 * This is mostly used for the input date,
			 * that's why default format is YYYY-MM-DD
			 *
			 * @param {string} as - format type
			 * @returns {string} - today formatted
			 */
			const today = (as = 'YYYY-MM-DD') => format(currentUtcUnix(), as);

			/**
			 *
			 * @param {*} timestamp
			 * @returns {string}
			 */

			const year = (timestamp) => {
				timestamp = checkTimestamp(timestamp);

				return toDate(timestamp).year();
			};

			/**
			 *
			 * @param {int} timestamp
			 * @param {int} type
			 * @param {string} unit
			 */
			const isBefore = (timestamp, time, unit) =>
				diff(timestamp, unit) < time;

			/**
			 * Formats date as absolute (https://material.io/design/communication/data-formats.html#date-and-time)
			 *
			 * Absolute style
			 * E.g: - Today at 2:00 pm
			 *      - Yesterday at 2:00 pm
			 *
			 * Before 12 hours uses Approximate style
			 * E.g: - a few seconds ago
			 *      - 10 minutes ago
			 *
			 * @param {int} daysPassed
			 */
			const absolute = (timestamp) => {
				timestamp = checkTimestamp(timestamp);
				if (isBefore(timestamp, 24, 'hour')) return from(timestamp);

				let as = null;
				let daysDiff = diff(timestamp, 'hour') / 24;

				// let dayNow = toDate().dayOfYear();
				// let daySource = toDate(timestamp).dayOfYear();

				if (daysDiff < 1) {
					as = formats.today;
					// } else if (
					// 	daysDiff < 2 &&
					// 	(dayNow - daySource === 1 || dayNow - daySource === -364)
					// ) {
					// 	as = formats.yesterday;
					// } else if (daysDiff < 7) {
					// 	as = formats.thisWeek;
				} else if (year(timestamp) !== year()) {
					as = formats.pastYears;
				} else {
					as = formats.overAWeek;
				}

				return format(timestamp, as);
			};

			/**
			 * Predefined formats
			 */
			const formats = {
				today: '[Today at] hh:mm a',
				yesterday: '[Yesterday at] hh:mm a',
				thisWeek: 'dddd [at] hh:mm a',
				overAWeek: 'MMM[.] D [at] hh:mm a',
				pastYears: 'MMM[.] D, YYYY [at] hh:mm a',
			};

			/**
			 * return functions that will be available to be used in components
			 */
			return {
				diff,
				toDate,
				format,
				today,
				year,
				absolute,
				toUnix,
				currentUtc,
				currentUtcUnix,
			};
		};
	},
};
