<template>
	<div class="content-header mb-4">
		<!-- class="d-flex flex-row align-items-start gap-0" -->
		<!-- class="d-flex flex-column flex-md-row align-items-start gap-3 gap-md-0" -->
		<div class="conent-header__main">
			<h1
				class="h2 word-breaker mb-0 me-3"
				style="max-width: 65%"
				id="header-bread-title"
			>
				{{ title }}
			</h1>
			<ElTooltip
				v-if="isTitleAbbreviated"
				:trigger-el-selector="`#header-bread-title`"
				placement="bottom"
			>
				{{ breadTitle }}
			</ElTooltip>

			<slot name="header-tags"></slot>

			<div class="content-header-buttons">
				<div class="h-header">
					<ViewHeaderActions
						:buttons-action="buttonsAction"
						:buttons-resource="buttonsResource"
						:need-confirm-deletion="needConfirmDeletion"
						@export="$emit('export')"
					/>

					<slot></slot>
				</div>
			</div>
		</div>
		<slot name="extra-info-header"></slot>
	</div>
</template>

<script>
	import ElTooltip from '@/components/ui/ElTooltip.vue';
	import ViewHeaderActions from '@/components/ViewHeaderActions.vue';

	export default {
		name: 'ViewHeader',
		components: {
			ViewHeaderActions,
			ElTooltip,
		},
		props: {
			stuckBar: {
				type: Boolean,
				default: true,
			},
			breadTitle: {
				type: String,
				default() {
					return '';
				},
			},
			buttonsAction: {
				type: Array,
				default() {
					return [];
				},
			},
			buttonsResource: {
				type: String,
				default: '',
			},
			needConfirmDeletion: {
				type: Boolean,
				default: false,
			},
			abbreviateTitleAt: {
				type: Number,
				default() {
					return -1;
				},
			},
		},
		emits: ['export'],
		computed: {
			title() {
				if (!this.isTitleAbbreviated) return this.breadTitle;
				return `${this.breadTitle.slice(0, this.abbreviateTitleAt)}...`;
			},
			isTitleAbbreviated() {
				return (
					this.abbreviateTitleAt >= 0 &&
					this.abbreviateTitleAt < this.breadTitle.length
				);
			},
		},
		mounted() {
			if (!this.stuckBar) return;
			const observer = new IntersectionObserver(
				([e]) =>
					e.target.classList.toggle('stuck', e.intersectionRatio < 1),
				{ threshold: [1] }
			);

			this.$nextTick(() => {
				observer.observe(document.querySelector('.content-header'));
			});
		},
	};
</script>

<style>
	.tags-collection + .content-header-buttons {
		margin-left: 0 !important;
	}
	.word-breaker {
		word-break: break-word; /* Break words at arbitrary points if necessary */
		overflow-wrap: break-word; /* Ensures text wraps within the container */
		white-space: normal; /* Allows the text to wrap to the next line */
	}
</style>
